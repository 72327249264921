import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../../components/layout"
import SEO from "/src/components/seo"
import "./Services.scss"
import ButtonContact from "../../components/BannerFullScreen/ButtonContact"
import HoverBox from "../../components/ColoredBoxes/HoverBox"
import CustomAccordion from "../../components/Accordion"
import { Link } from "gatsby"
import WhiteBox from "../../components/ColoredBoxes/WhiteBox"
import ContactForm from "../../components/Contact/contactForm/ContactForm"
import BannerFullScreen from "../../components/BannerFullScreen/BannerFullScreen"
import BoxWithHeader from "../../components/ColoredBoxes/BoxWithHeader"

const bannerContent = {
    title: "Microsoft Dynamics 365 and Power Platform",
    content: "Accelerate your business with Microsoft Dynamics 365 and Power Platform to streamline operations, gain valuable insights, and deliver exceptional customer experiences.",
    backgroundImage: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/genai-consulting-banner.jpg`,
    layout: "2-columns",
    image: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/microsoft365-power-platform-intro.png`,
    textColor: "light-yellow",
    buttonText: "Talk to Experts",
    showArrow: true
}

const dynamics365 = [
    {
        number: "27%",
        content: "of the global ERP market relies on Microsoft Dynamics."
    },
    {
        number: "49%",
        content: "of Microsoft Dynamics users are mid-sized businesses (50-1,000 employees)."
    },
    {
        number: "2.5%",
        content: "of Microsoft’s total revenue in 2023 came from Dynamics."
    },
    {
        number: "15%",
        content: "of users are in the Technology and Services industry, while 8% are in Computer Software."
    }
]

const powerPlatform = [
    {
        number: "33M",
        content: "people use Power Platforms each month, with a 50% YoY growth."
    },
    {
        number: "350K+",
        content: "organizations leverage Power Automate to streamline workflows monthly."
    },
    {
        number: "10M",
        content: "active users rely on Power Automate monthly."
    },
    {
        number: "63K",
        content: "organizations have used Power Platform & Dynamics Copilot."
    }
]

const businessApplications = [
    {
        title: "Sales",
        content: "Empower the sales team to close deals faster. Improve sales win rate with advanced lead scoring and opportunity management.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-application-1.svg`
    },
    {
        title: "Customer Service",
        content: "Gain a 360-degree view of your customers to deliver exceptional experiences with personalized service.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-application-2.svg`
    },
    {
        title: "Marketing",
        content: "Execute marketing campaigns, lead management, and customer insights.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-application-3.svg`
    },
    {
        title: "Field Service",
        content: "Manage field service operations, scheduling, work order management, and inventory.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-application-4.svg`
    },
    {
        title: "Human Resources",
        content: "Manage employee lifecycle, payroll, benefits, talent acquisition, and workforce analytics.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-application-5.svg`
    },
    {
        title: "Operations",
        content: "Optimize your supply chain, ensure compliance, and improve operational efficiency.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-application-6.svg`
    },
    {
        title: "Finance",
        content: "Streamline financial operations, gain real-time visibility into your financial health, and cut procurement costs.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-application-7.svg`
    },
    {
        title: "Product Visualization",
        content: "Combine physical-world data with virtual data, enabling salespeople to showcase products to customers on a mobile device.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-application-8.svg`
    }
]

const crmvsERP = [
    {
        title: "Customer Relationship Management (CRM)",
        content: "Manage sales, marketing, and customer service activities in one platform."
    },
    {
        title: "Enterprise Resource Planning (ERP)",
        content: "Optimize finance, operations, supply chain, and human resources processes."
    },
    {
        title: "industry-specific solutions ",
        content: "Streamline financial operations, gain real-time visibility into your financial health, and cut procurement costs."
    }
]

const powerPlatformEngine = [
    {
        title: "Power BI",
        subTitle: "Business analytics service",
        content: "Focus on data visualization and business intelligence, transforming data into interactive dashboards and reports.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/logo-power-bi.png`
    },
    {
        title: "Power Apps",
        subTitle: "Application development platform",
        content: "Build custom apps on various platforms to solve business challenges without extensive coding.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/logo-power-apps.png`
    },
    {
        title: "Power Automate",
        subTitle: "Workflow automation tool",
        content: "Automate repetitive tasks and streamline workflows across different applications and services.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/logo-power-automate.png`
    },
    {
        title: "Power Virtual Agents",
        subTitle: "Intelligent virtual agents",
        content: "Build intelligent chatbots to enhance customer and employee interactions.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/logo-power-virtual-agents.png`
    },
]

const businessCapabilities = [
    {
        title: "Enhanced Productivity",
        content: ["Streamline workflows and processes.","Improve data accessibility and insights."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-capabilities-1.svg`
    },
    {
        title: "Cost Efficiency",
        content: ["Reduce the need for multiple disparate systems.","Lower the total cost of   ownership."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-application-7.svg`
    },
    {
        title: "Scalability",
        content: ["Easily scalable to meet growing business needs.","Adapt to changing market conditions with flexible tools."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-capabilities-2.svg`
    },
    {
        title: "Customization and Flexibility",
        content: ["Provide tailored solutions to fit specific business requirements.","Build custom applications and workflows without extensive coding."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-capabilities-3.svg`
    },
    {
        title: "Security and Compliance",
        content: ["Robust security features and compliance with industry standards.","Ensure data protection and regulatory compliance."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-capabilities-4.svg`
    },
    {
        title: "Improved Collaboration",
        content: ["Foster better teamwork through integrated communication tools.","Enhance collaboration across departments with shared data and insights."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/business-capabilities-5.svg`
    },
]

const ourApproach = [
    {
        title: "Consultation and Needs Assessment",
        content: "Understand your business requirements and develop a tailored strategy."
    },
    {
        title: "Implementation and Integration",
        content: "Seamlessly integrate Dynamics 365 and Power Platform into your existing systems."
    },
    {
        title: "Customization and Development",
        content: "Develop custom solutions to address your unique business challenges."
    },
    {
        title: "Training and Support",
        content: "Equip your team with the knowledge and skills to maximize the platform's potential."
    },
    {
        title: "Ongoing Maintenance and Optimization",
        content: "Ensure your systems are always running at peak performance."
    }
]

const experienceWithNeurond = [
    {
        title: "Expertise and Experience",
        content: "Our team of certified professionals has extensive experience implementing and optimizing Microsoft Dynamics 365 and Power Platform.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/icon-experience-1.svg`
    },
    {
        title: "Customer-Centric Approach",
        content: "We prioritize your business goals and work closely with you to achieve them.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/icon-experience-2.svg`
    },
    {
        title: "Proven Track Record",
        content: "Our successful implementations speak for themselves, with numerous satisfied clients across various industries.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/icon-experience-3.svg`
    },
    {
        title: "Comprehensive Support",
        content: "We provide end-to-end support from consultation to post-implementation to ensure the long-term success of your investment",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/icon-experience-4.svg`
    },
]

const otherServices = [
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-chip.png`,
        title: "AI Development",
        linkTo: "/services/artificial-intelligence"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-health.png`,
        title: "Business Intelligence",
        linkTo: "/services/business-intelligence"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-robot-arm.png`,
        title: "Data Engineering",
        linkTo: "/services/data-engineering"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-ioi.png`,
        title: "Data Science <span class=\"d-inline-block\">And Analytics</span> Solutions",
        linkTo: "/services/data-science-and-analytics"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-ai-consulting.png`,
        title: "AI Consutling",
        linkTo: "/services/artificial-intelligence-consulting"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-mlops.png`,
        title: "MLOps Consulting",
        linkTo: "/services/mlops-consulting"
    },
]

const Microsoft365andPowerPlatform = () => {
    const { t } = useTranslation()
    return (
        <Layout showHeaderBG={true}>
            <SEO title={t("Microsoft Dynamics 365 and Power Platform")} description={"Microsoft Dynamics 365 and Power platform help streamline operations, boost productivity, and drive digital transformation for your organization."}/>
            <BannerFullScreen item={bannerContent} />

            <section className="content-background padding-y-100 blue-gradient-bg">
                <div className="wrapper">
                    <h2 className="h2-text text-center mb-4">Your Path to Digital Transformation</h2>
                    <p className="text-center mb-5">Microsoft Dynamics 365 and Power Platform are a powerful combination of tools that empower businesses to create custom apps, automate processes, analyze data, and manage customer relationships efficiently.</p>

                    <h3 className="h3-text text-center primary-blue mb-4">The Numbers Don’t Lie </h3>
                    <div className="row m-minus-15">
                        <div className="col-lg-6 col-12">
                            <div className="light-purple-box">
                                <div className="row align-items-center justify-content-center mb-4">
                                    <div className="col-2 px-0">
                                        <img className="img-fluid" src={`${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/dynamics-365.png`} alt="dynamics-365"/>
                                    </div>
                                    <p className="h4-text pl-3 --font-weight-bold">Dynamics 365</p>
                                </div>
                                {
                                    dynamics365.map((item, index) => {
                                        return(
                                            <div className="white-box row mt-2 py-4" key={index} style={{height: 'auto'}}>
                                                <p className="col-xl-3 col-lg-4 col-3 pl-0 primary-blue h2-text --font-weight-exbold">{item.number}</p>
                                                <p className="col-xl-9 col-lg-8 col-9 px-0">{item.content}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 mt-4 mt-lg-0">
                            <div className="light-blue-box">
                                <div className="row align-items-center justify-content-center mb-4">
                                    <div className="col-2 px-0">
                                        <img className="img-fluid" src={`${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/power-platform.png`} alt="Power Platform "/>
                                    </div>
                                    <p className="h4-text pl-3 --font-weight-bold">Power Platform</p>
                                </div>
                                {
                                    powerPlatform.map((item, index) => {
                                        return(
                                            <div className="white-box row mt-2 py-4" key={index} style={{height: 'auto'}}>
                                                <p className="col-xl-4 col-lg-5 col-4 pl-0 dark-green h2-text --font-weight-exbold">{item.number}</p>
                                                <p className="col-xl-8 col-lg-7 col-8 px-0">{item.content}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="padding-y-100 pb-0">
                <div className="wrapper">
                    <div className="col-lg-6 px-0 align-self-start">
                        <h2 className="h2-text"><span className="--font-weight-exbold">Dynamics 365</span><span className="d-block primary-blue --font-weight-normal">Intelligent Business Applications</span></h2>
                        <p className="mt-4">Dynamics 365 offers a comprehensive suite of intelligent tools designed to optimize business processes, from sales to service, marketing, and operations.</p>
                    </div>

                    <div className="row m-minus-15">
                        {
                            businessApplications.map((item, index) => {
                                return(
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-5" key={index}>
                                        <img className="img-fluid" src={item.icon} alt={item.title}/>
                                        <h3 className="h4-text --font-weight-bold primary-blue my-4">{item.title}</h3>
                                        <p className="secondary-blue">{item.content}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="row m-minus-15 mt-5">
                        <p className="col-12">With integrated CRM and ERP capabilities, Dynamics 365 provides valuable insights driven by AI, helping businesses make data-driven decisions and improve customer satisfaction.</p>
                        {
                            crmvsERP.map((item, index) => {
                                return(
                                    <div className="col-md-4 col-12 mt-4" key={index}>
                                        <BoxWithHeader item={item} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

            <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/power-platform-bg.png)`}} >
                <div className="wrapper">
                    <div className="row m-minus-15">
                        <div className="col-lg-6 col-12">
                            <h2 className="h2-text"><span className="--font-weight-exbold">Power Platform</span><span className="d-block primary-blue --font-weight-normal">Your Innovation Engine</span></h2>
                            <p className="mt-4">Power Platform is a set of applications, connectors, and a data platform (Dataverse) to create a robust environment for building custom apps, automating workflows, and analyzing data.</p>
                        </div>
                        <div className="col-lg-6 col-12 mt-5 mt-lg-0 grid-2by2">
                            {
                                powerPlatformEngine.map((item, index) => {
                                    return(
                                        <div className="py-5 px-4 text-center" style={{backgroundColor: "#EAF3FF",borderRadius: "10px"}}>
                                            <img className="img-fluid mb-2" src={item.icon} alt={item.title}/>
                                            <h3 className="h4-text primary-blue mb-2 --font-weight-exbold text-center">{item.title}</h3>
                                            <p className="primary-blue --font-weight-bold mb-3">{item.subTitle}</p>
                                            <p>{item.content}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className="content-background padding-y-100 blue-gradient-bg-2">
                <div className="wrapper">
                    <div className="col-9 px-0">
                        <h2 className="h2-text --font-weight-exbold text-center mb-4">Maximize Your Business Capabilities with <span className="primary-blue">Microsoft Power Platform and Dynamics 365</span></h2>
                        <p className="text-center">Integrate Dynamics 365 and Power Platform into your business and experience their combined power to boost productivity, reduce cost, and scale your operations seamlessly.</p>
                    </div>

                    <div className="row m-minus-15 mt-4">
                        {
                            businessCapabilities.map((item, index) => {
                                return(
                                    <div className="col-lg-4 col-sm-6 col-12 mt-4" key={index}>
                                        <div className="white-box p-4">
                                            <p className="text-center"><img className="img-fluid float-none" src={item.icon} alt={item.title}/></p>
                                            <h3 className="h4-text primary-blue --font-weight-bold my-3 text-center">{item.title}</h3>
                                            {
                                                item.content.map((text) => {
                                                    return(
                                                        <p className="p-3 mt-2" style={{backgroundColor:"#F5FBFF"}}>{text}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

            <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/power-platform-bg.png)`}} >
                <div className="wrapper">
                    <div className="row m-minus-15">
                        <div className="col-md-6 col-12 secondary-blue">
                            <h2 className="h2-text mb-5">Our Approach to Implementing <span className="gradient-text d-inline-block">Microsoft Dynamics 365 and Power Platform</span></h2>
                            <p className="mb-4">We partner with you every step of the way to ensure a successful implementation and maximize your return on investment.</p>
                        </div>
                        <div className="offset-lg-1 col-lg-5 col-md-6 col-12">
                            {
                                ourApproach.map((item, index) => {
                                    if(ourApproach.length == index + 1) {
                                        return (
                                            <div key={index}>
                                                <p className="h4-text primary-blue mb-2">{item.title}</p>
                                                <p className="sub-content secondary-blue">{item.content}</p>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={index}>
                                                <p className="h4-text primary-blue mb-2">{item.title}</p>
                                                <p className="sub-content  secondary-blue">{item.content}</p>
                                                <hr className="my-3"/>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/experience-with-neurond-bg.png)`}}>
                <div className="wrapper">
                    <h2 className="h2-text gradient-yellow-text text-center">Experience the Difference with Neurond</h2>

                    <div className="row m-minus-15 mt-4">
                    {
                        experienceWithNeurond.map((item, index) => {
                            return(
                                <div className="col-lg-3 col-sm-6 col-12 mt-4" key={index}>
                                    <div className="white-box">
                                        <p className="text-center"><img className="img-fluid float-none" src={item.icon} alt={item.title}/></p>
                                        <h3 className="h4-text primary-blue --font-weight-bold my-3 text-center">{item.title}</h3>
                                        <p className="text-center">{item.content}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </section>

            <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/microsoft-powerplatform/cta-banner-bg.jpg)`}}>
                <div className="wrapper --flex-row align-items-center">
                    <div className="col-xl-9 col-lg-8 col-md-7 col-12  px-0">
                        <h2 className="h2-text primary-blue mb-4">Begin Your Digital Transformation <span className="d-inline-block">Journey Today</span></h2>
                        <p>Take the first step towards digital transformation. Contact us today for a free consultation. </p>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-5 col-12 text-lg-center mt-4 mt-md-0 px-0">
                        <ButtonContact item={{customClass:"--bg-yellow", customText:"Meet Our Consultants", linkTo: "/contact", showArrow: true}}/>
                    </div>
                </div>
            </section>

            <section id="contact-form" className="genai-contact content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-form-1.jpg)`}}>
                <div className="wrapper">
                    <div className="content-background padding-y-50 px-4" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-form-2.jpg)`, borderRadius: "9px"}}>
                        <div className="row py-4">
                            <div className="order-lg-2 offset-lg-1 col-lg-4 col-12 mb-5 mb-lg-0">
                                <h2 className="h2-text --uppercase mb-5">
                                    <span className="d-block text-white">Ready for AI Transformation?</span>
                                    <span className="d-block light-yellow ">Let's Connect</span>
                                </h2>
                                <p className="text-white">Embrace the future, boost your business performance, and join the AI revolution with Neurond AI Consulting Service today.</p>
                            </div>
                            <div className="order-lg-1 offset-lg-1 col-lg-5 col-12">
                                <div className="genai-contact__form">
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-other-services.jpg)`}}>
                <div className="wrapper">
                    <div className="row m-minus-15">
                        <div className="col-12 mb-5">
                            <p className="sub-text-h2">SERVICES</p>
                            <h2 className="h2-text light-yellow --uppercase">
                                Other Services
                            </h2>
                        </div>
                        <div className="col-12">
                            <div className="grid-3by2">
                            {
                                otherServices.map((item, index) => {
                                    return(
                                        <WhiteBox item={item}/>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      </Layout>
    )
  }

export default Microsoft365andPowerPlatform
