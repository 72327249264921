import React from "react"
import "./WhiteBox.scss"

const BoxWithHeader = prop => {
    return (
        <div className="box-w-header">
            <div className="box-header">
                <h3 className="h3-text text-white --uppercase text-center">{prop.item.title}</h3>
            </div>
            <div className="box-body text-center">
                {prop.item.content}
            </div>
        </div>
    )
}
export default BoxWithHeader
