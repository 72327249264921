import React from "react"
import "./WhiteBox.scss"
import { Link } from "gatsby"

const HoverBox = prop => {
    return (
        <div className={'hover-colored-box ' + prop.item.setting.hoverClass}>
            {
                prop.item.icon && (
                    <p className="hover-colored-box__img">
                        <img className="img-fluid" alt={prop.item.title + "icon"} src={prop.item.icon}/>
                    </p>
                )
            }
            <h3 className={'h4-text --font-weight-bold hover-colored-box__title '}>
                {
                    prop.item.linkTo && prop.item.linkTo ? (<Link className="secondary-blue" to={prop.item.linkTo} dangerouslySetInnerHTML={{ __html: prop.item.title }}></Link>) : (<span dangerouslySetInnerHTML={{ __html: prop.item.title }}></span>)
                }
            </h3>
            {
                prop.item.content && (
                    <p className="hover-colored-box__content">{prop.item.content}</p>
                )
            }
        </div>
    )
}
export default HoverBox
